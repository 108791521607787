import { useMutation, useQueryClient } from '@tanstack/react-query';
import { countBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import {
    BYODOrderItemDto,
    byodOrdersCacheKey,
    byodOrderService,
    ItemError,
    itemsService,
    ReceiveBYODOrderItemPayload,
} from '@hofy/api-admin';
import { isSerialNumberRequired } from '@hofy/api-shared';
import { isNotFoundError, isResponseError } from '@hofy/rest';
import {
    FormFieldRecord,
    isRequired,
    isRequiredIf,
    useForm,
    useFormArrayField,
    useToast,
    validateArrayField,
    validator,
} from '@hofy/ui';

import {
    emptyReceiveBYODOrderItem,
    emptyReceiveBYODOrderItemDetail,
    ReceiveBYODItemFormData,
    ReceiveBYODOrderItemDetailFormData,
    ReceiveBYODOrderItemFormData,
    ReceiveBYODOrderItemValidData,
} from './types/ReceiveBYODOrderItemFormData';

const useExistingItemCodeCheck = (): [
    Set<string>,
    (payload: ReceiveBYODOrderItemPayload) => Promise<void>,
] => {
    const [existingItemCodes, setExistingItemCodes] = useState<Set<string>>(new Set());
    const existing = new Set<string>();

    const processExistingError = async ({ items: itemSent }: ReceiveBYODOrderItemPayload) => {
        for await (const item of itemSent) {
            try {
                await itemsService.getItemCode(item.itemCode);
                existing.add(item.itemCode);
            } catch (error) {
                // This empty catch block is needed to handle HTTP errors when
                // server cannot find a given item code; such as returning 404
                // when the code is not an existing one.
                // NOTE: This is expected behavior.
                if (!isNotFoundError(error)) {
                    throw error;
                }
            }
        }
        if (existing.size) {
            setExistingItemCodes(existing);
        }
    };
    return [existingItemCodes, processExistingError];
};

interface UseReceiveBYODOrderItemsProps {
    onSuccess(): void;
    byodOrderItem: BYODOrderItemDto | null;
    isWarehouseBinRequired: boolean | undefined;
}

export const useReceiveBYODOrderItems = ({
    onSuccess,
    byodOrderItem,
    isWarehouseBinRequired,
}: UseReceiveBYODOrderItemsProps) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const [existingItemCodes, processExistingError] = useExistingItemCodeCheck();
    const duplicateItemCodes = useRef<Set<string>>(new Set());
    const duplicateSerialNumbers = useRef<Set<string>>(new Set());

    const mutation = useMutation({
        mutationFn: (data: ReceiveBYODOrderItemValidData) =>
            byodOrderService.receiveBYODOrderItem(data.byodOrderItemId, data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [byodOrdersCacheKey] });
            showToast({
                type: 'positive',
                message: 'BYOD order items received',
            });
            form.setValues({
                items: [],
            });
            onSuccess();
        },
        onError: async (error, payload) => {
            if (isResponseError(error) && error?.response?.code === ItemError.ExistingCode) {
                processExistingError(payload);
            }
        },
    });

    const form = useForm<ReceiveBYODOrderItemFormData, ReceiveBYODOrderItemValidData>({
        initial: emptyReceiveBYODOrderItem(byodOrderItem?.product.category),
        initialDeps: [byodOrderItem],
        onSubmit: data => {
            if (data.items.length > 1) {
                const lastItem = data.items[data.items.length - 1];
                if (!lastItem.serialNumber && !lastItem.itemCode) {
                    // last array item is empty and may be skipped
                    data.items = data.items.slice(0, -1);
                }
            }
            mutation.mutate(data);
        },
        validate: validator<ReceiveBYODOrderItemFormData>({
            items: validateArrayField<ReceiveBYODOrderItemValidData, 'items'>({
                selfRules: isRequired('At least one item is required'),
                fieldsValidator: validator<ReceiveBYODItemFormData>({
                    serialNumber: [
                        isRequiredIf(() => {
                            const category = byodOrderItem?.product.category;
                            return !category || isSerialNumberRequired(category);
                        }, 'Serial number is required'),
                        value => {
                            if (duplicateSerialNumbers.current.has(value)) {
                                return 'Serial number is duplicated';
                            }
                        },
                    ],
                    itemCode: [
                        isRequired('Item code is required'),
                        value => {
                            if (duplicateItemCodes.current.has(value)) {
                                return 'Item code is duplicated';
                            }

                            if (existingItemCodes.has(value)) {
                                return 'Item code already exists';
                            }
                        },
                    ],
                    warehouseBinIdentifier: isRequiredIf(
                        () => !!isWarehouseBinRequired,
                        'Enter a valid warehouse bin',
                    ),
                    receivedOn: isRequired('Date received required'),
                }),
                shouldSkip(value, field) {
                    // skip the validation of the last field if it's empty (it
                    // is omitted in onSubmit above)
                    return (
                        value.length > 1 &&
                        value.indexOf(field) === value.length - 1 &&
                        !field.itemCode &&
                        !field.serialNumber
                    );
                },
            }),
        }),
        validateDeps: [byodOrderItem, existingItemCodes, duplicateSerialNumbers],
    });

    useEffect(() => {
        const { items } = form.fields;

        const serialNumbers = countBy(items.value.map(item => item.serialNumber));
        const newDuplicateSerialNumbers = new Set(
            Object.entries(serialNumbers)
                .filter(([, count]) => count > 1)
                .map(([key]) => key),
        );
        if (newDuplicateSerialNumbers !== duplicateSerialNumbers.current) {
            duplicateSerialNumbers.current = newDuplicateSerialNumbers;
        }

        const itemCodes = countBy(items.value.map(item => item.itemCode));
        const newDupicateItemCodes = new Set(
            Object.entries(itemCodes)
                .filter(([, count]) => count > 1)
                .map(([key]) => key),
        );
        if (newDupicateItemCodes !== duplicateItemCodes.current) {
            duplicateItemCodes.current = newDupicateItemCodes;
        }
    }, [form]);

    const [scansLeft, setScansLeft] = useState<number>(0);

    const isItemEmpty = (item: FormFieldRecord<ReceiveBYODOrderItemDetailFormData>) => {
        return !item.itemCode?.value && !item.serialNumber?.value;
    };

    const items = useFormArrayField(form.fields.items, emptyReceiveBYODOrderItemDetail);

    // automatically add a new item row if the last item is not empty up to limit
    useEffect(() => {
        const lastItem = items.fields[items.fields.length - 1];
        if (!lastItem || (!isItemEmpty(lastItem.api) && items.fields.length < scansLeft)) {
            items.add();
        }

        items.fields.forEach((item, index) => {
            if (index + 1 === items.fields.length) {
                return;
            }
            if (isItemEmpty(item.api)) {
                items.remove(item.key);
            }
        });
    }, [items, scansLeft]);

    return {
        form,
        items,
        setScansLeft,
        isItemEmpty,
        isLoadingMutation: mutation.isPending,
        isErrorMutation: mutation.isError,
    };
};
