import { getEnumValues } from '@hofy/global';

export enum OrganizationContractStatus {
    Active = 'active',
    Expired = 'expired',
    DoNotRenew = 'do_not_renew',
}

export const allOrganizationContractStatuses =
    getEnumValues<OrganizationContractStatus>(OrganizationContractStatus);
