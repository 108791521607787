import { stringifyUrl } from 'query-string';

import { PageRequest, PageResponse, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { OrganizationContractDto } from './types/OrganizationContractDto';

class OrganizationContractService {
    public listOrganizationContracts = async (
        organizationId: UUID,
        page: PageRequest,
    ): Promise<PageResponse<OrganizationContractDto>> => {
        return restClient.getJson<PageResponse<OrganizationContractDto>>(
            stringifyUrl({
                url: `/api/admin/organizations/${organizationId}/organization-contracts`,
                query: {
                    ...page,
                },
            }),
        );
    };
}

export const organizationContractService = new OrganizationContractService();
